import React, { useContext, useState, useEffect } from 'react';
import './cart.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import CartItem from '../../components/cartitem/CartItem';
import { CartContext } from '../../context/CartContext';
import { TransContext } from '../../context/TransContext';
import { AuthContext } from '../../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import CheckOut from '../checkout/CheckOut';
import { useCurrency } from '../../context/Currency';
import axiosInstance from '../../axiosInstance';
import ZipcodeModal from './ZipCode.model';

export default function Cart() {
   const baseURL = process.env.REACT_APP_API_BASE_URL
   const { symbol } = useCurrency();
   const [isOutOfStock, setIsOutOfStock] = useState(false);
   const navigate = useNavigate();
   const { cart } = useContext(CartContext);
   const { priceDetails } = useContext(TransContext);
   const { dispatch, user } = useContext(AuthContext);
   const [openModal, setOpenModal] = useState(false);
   const [openZipModal, setOpenZipModal] = useState(false);
   // Scroll to top when component mounts
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   // Function to check if any item in the cart is out of stock
   const getData = async (productId) => {
      const { data } = await axiosInstance.get(`/product/find/${productId}`);
      return data;
   };



   useEffect(() => {
      const fetchData = async () => {
         let outOfStockFound = false;
         for (let i of cart) {
            const resData = await getData(i.productId);
            if (resData?.stocks === 0) {
               outOfStockFound = true;
               break;
            }
         }
         setIsOutOfStock(outOfStockFound);
      };

      if (cart && cart.length > 0) {
         fetchData();
      }
   }, [cart]);

   // Handle checkout button click
   const handleClick = () => {
      console.log(user, "user")
      console.log(user?.address, "address")
      console.log(user?.address?.zipCode, "zipCode")
      console.log(user?.address?.country, "country")
      if (user !== null && (!user?.address?.country || !user?.address?.zipCode)) {
         setOpenZipModal(true);
      }
      else if (user != null) {
         setOpenModal(true);
      } else {
         navigate('/login');
      }
   };

   const handleZipcodeSubmit = async (addressObj) => {
      try {
         const res = await axiosInstance.post(`${baseURL}/user/updateaddress/${user._id}`, addressObj, { withCredentials: true })
         if (res) {
            setOpenZipModal(false);
            setOpenModal(true);
            dispatch({
               type: "LOGIN_SUCCESS",
               payload: res.data, // Update user data in context
            });
         }
      } catch (error) {
         console.log(error, "error")
      }

   };

   return (
      <>
         <Navbar />
         <div className='cart'>
            <div className='cartTitle'>
               <span className='cartheading'>Shopping Cart</span>
            </div>
            {
               (cart === null || cart.length === 0) ? (
                  <span className='cartEmpty'>Your cart is empty 🙂‍↕️</span>
               ) : (
                  <>
                     <div className='cartContainer'>
                        <div className='cartHead'>
                           <span className='cartHeadFirst'>Cart Items</span>
                           <span className='cartHeadSecond'>Total Price</span>
                        </div>
                        <div className='cartItems'>
                           {
                              cart.map((cartDetails, i) => (
                                 <CartItem productId={cartDetails.productId} productSize={cartDetails.size} productQnt={cartDetails.qnt} key={i} />
                              ))
                           }
                        </div>
                     </div>
                     <div className='grandTotal'>
                        <div className='totalPriceDesc'>
                           <div className='totalPriceDiv'>
                              <span className='subTotal'>Grand Total:</span>
                              <span className='subTotal'>{symbol}{priceDetails.totalPrice.toFixed(2)}</span>
                           </div>
                           {priceDetails.totalSaving > 0 && <div className='totalPriceDiv'>
                              <span className='totalSaving'>Total Saving:</span>
                              <span className='totalSaving'>{symbol}{priceDetails.totalSaving}</span>
                           </div>}
                        </div>

                        {/* Show message if any item is out of stock */}
                        {isOutOfStock && (
                           <div className="outOfStockMessage">
                              Remove out of stock item(s) to proceed to checkout.
                           </div>
                        )}
                        <div className='checkOutBtn'>
                           <Link to='/store'><button className='continue'>Continue Shopping</button></Link>
                           <button
                              className='checkOut'
                              onClick={handleClick}
                              disabled={isOutOfStock} // Disable checkout if out of stock
                           >
                              Checkout
                           </button>
                        </div>

                        {/* Delivery charge note */}
                        <div className='deliveryChargeNote'>
                           <small>Note: Delivery charges may apply at checkout.</small>
                        </div>
                     </div>
                  </>
               )
            }
         </div>
         <Footer />
         {openModal && <CheckOut setModal={setOpenModal} cartItems={cart} />}
         {openZipModal && <ZipcodeModal onSubmit={handleZipcodeSubmit} onClose={() => setOpenZipModal(false)} />}
      </>
   );
}
