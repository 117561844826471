import React, { useEffect } from 'react'

//importing css
import './about.css'


//importing components
import Navbar from '../../components/navbar/Navbar'
import Footer from '../../components/footer/Footer'

//importing motion for animation
import { motion } from 'framer-motion'

//importing images
// import Person from '../../assets/mitbhuva.png'
// import Tree from '../../assets/tree_image.png'
// import about_us_logo from '../../assets/about-us2.avif'
// import about_us_logo from '../../assets/website slideshow-04-1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import Story1 from '../../assets/story1.jpeg';
import Story2 from '../../assets/story2.jpeg';
import Story3 from '../../assets/story3.jpeg';
import Story4 from '../../assets/story4.jpeg';
import Story5 from '../../assets/story5.jpeg';
import Story6 from '../../assets/story6.jpeg';
import { useNavigate } from 'react-router-dom'

export default function About() {
    const nav = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const fadeInUp = {
        hidden: { opacity: 0, y: 50 },
        show: { opacity: 1, y: 0 }
    }
    const transition = {
        duration: .5,
        staggerChildren: 0.2
    }
    // const sectionVariants = {
    //     hidden: { opacity: 0, y: 50 },
    //     visible: { opacity: 1, y: 0 }
    // };
    const storyContent = [
        {
            image: Story1,
            header: "Our Story",
            content: "From humble beginnings to a celebrated legacy, our story is one of passion, perseverance, and purpose. We embarked on a journey to blend the rich cultural traditions of our heritage with the modern sensibilities of today. Each thread we craft symbolizes a deep connection to our roots while embracing the endless possibilities of innovation."
        },
        {
            image: Story2,
            header: "Heritage Journey",
            content: "Our heritage journey began with a vision to preserve the timeless techniques of craftsmanship handed down through generations. By combining these traditional skills with modern designs, we create pieces that tell stories of artistry, culture, and community, ensuring that every creation remains a testament to our unwavering commitment to excellence."
        },
        {
            image: Story3,
            header: "Legacy of Craft",
            content: "Rooted in centuries-old traditions, our legacy of craft embodies the essence of dedication, skill, and creativity. Each piece is meticulously designed and woven with care, representing a harmonious balance between age-old practices and contemporary aesthetics, making our creations both timeless and unique."
        },
        {
            image: Story4,
            header: "Timeless Tradition",
            content: "Honoring the past while shaping the future, we remain devoted to preserving the timeless traditions that define our craft. Every stitch and every detail in our work reflects the beauty of cultural heritage, creating a lasting impression that resonates across generations and celebrates the artistry of our ancestors."
        },
        {
            image: Story5,
            header: "Threaded Tales",
            content: "Every thread we weave carries a story – a tale of heritage, craftsmanship, and innovation. Through our creations, we strive to blend the beauty of traditional artistry with the progress of modern techniques, delivering pieces that are both meaningful and functional, connecting the past with the present in every stitch."
        },
        {
            image: Story6,
            header: "Woven Heritage",
            content: "Our work represents the delicate interplay of heritage and innovation. By weaving tradition into every design and infusing it with forward-thinking ideas, we craft products that are as enduring as they are inspiring. Each piece is a celebration of the craftsmanship and dedication that define our brand."
        }
    ];
    const storyContent2 = [
        { image: Story1, content: "Crafting Tradition, Embracing Innovation", header: "Our Story" },
        { image: Story2, content: "Where Heritage Meets Modern Threads", header: "Heritage Journey" },
        { image: Story3, content: "Rooted in Tradition, Woven for Today", header: "Legacy of Craft" },
        { image: Story4, content: "Honoring the Past, Weaving the Future", header: "Timeless Tradition" },
        { image: Story5, content: "Heritage in Every Thread, Progress in Every Stitch", header: "Threaded Tales" },
        { image: Story6, content: "Threads of Heritage, Fabric of Innovation", header: "Woven Heritage" }
    ];
    return (
        <>
            <Navbar></Navbar>
            <div className='about'>
                {/* <div className="headerImageContainer">
                    <img className="headerImg" src={about_us_logo} alt={`FAQ}`} />
                    <div className='tint flex flex-col'>
                        <h3 className='text-white font-thin text-3xl md:text-6xl'>About us</h3>
                        <div className='text-white tracking-[4px] mt-3 text-center text-sm md:text-lg'>QUICK ANSWERS FOR YOUR PEACE OF MIND</div>
                    </div>
                </div> */}
                {/* <div className='topheader'> */}
                <Swiper
                    className='mySwiper'
                    modules={[Pagination, Scrollbar, A11y, Autoplay]}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    spaceBetween={0}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    pagination={{ clickable: true }}
                >
                    {storyContent2.map((x, i) => (
                        <SwiperSlide key={i}>
                            <div className="headerImageContainer cursor-pointer" onClick={() => nav('/about')}>
                                <img className="headerImg" src={x.image} alt={`slide ${i + 1}`} />
                                <div className='tint flex flex-col'>
                                    <h3 className='text-white font-thin text-3xl md:text-6xl'>{x.header}</h3>
                                    <div className='text-white tracking-[4px] mt-3 text-center text-sm md:text-lg'>{x.content}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                {/* </div> */}
                <div className="bg-gray-100 text-gray-800 min-h-screen p-2">
                    <div className="max-w-4xl mx-auto py-8">
                        <section className="text-center mb-12">
                            <h2 className="text-3xl font-bold text-gray-700">The Beauty of Handcrafted Art</h2>
                            <p className="mt-4 text-lg leading-relaxed">
                                Hand-woven and hand-made products represent the pinnacle of an artisan’s craft.
                                Each piece reflects the beauty of traditional techniques combined with artistic
                                dedication.
                            </p>
                            <p className="mt-4 text-lg leading-relaxed">
                                Let’s support artisans who pour their skill and soul into these creations, keeping
                                ancient crafts alive for future generations.
                            </p>
                            <div className="mt-6 italic text-gray-600">
                                - Concept by Neelam Shukla, Designer and Advocate for Sustainable Crafts
                            </div>
                            <p className="mt-4 text-lg leading-relaxed">
                                Neelam believes in the preservation of handcraft techniques developed centuries ago,
                                blending modern and traditional methods to create timeless pieces. Your support helps
                                transform the lives of artisans, sustaining their invaluable contributions to art.
                            </p>
                        </section>
                        {/* Contact Information */}
                        <motion.section
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                            className="text-center mb-8"
                        >
                            <h2 className="text-3xl font-bold">Contact us</h2>
                            {/* <p className="mt-4">Mon – SAT | 10:00 AM – 6:00 PM (IST)</p> */}
                            <p className="mt-4">
                                <a href="mailto:shop@kashmirloom.com" className="text-blue-600 hover:underline">
                                    weavesfabrics12@gmail.com
                                </a>
                            </p>
                        </motion.section>
                        {/* Shipping Information */}
                        {/* <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Shipping Information</h3>
                            <p>
                                Each product has a different lead time, please check the product details for shipping lead time.
                                International orders are usually delivered within 10-15 business days and domestic orders within
                                7-10 business days.
                            </p> */}
                        {/* <p className="mt-4">For all orders within India, we offer free shipping.</p> */}
                        {/* <p>$40 flat shipping for all international orders.</p> */}
                        {/* <p className="mt-4">
                                We use the most reliable courier services for deliveries, including FedEx, UPS, and DHL.
                            </p>
                        </motion.section> */}

                        {/* Payment Information */}
                        {/* <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Payment</h3>
                            <p>
                                For shipping to India, we accept all major debit & credit cards (including Mastercard, Visa &
                                American Express) and provide Net Banking options.
                            </p>
                            <p>
                                For all international transactions, we accept major Credit Cards (including Mastercard, Visa &
                                American Express).
                            </p>
                        </motion.section> */}

                        {/* Refund Policy */}
                        {/* <motion.section
                            className="bg-white shadow-md rounded-lg p-6 mb-8"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <h3 className="text-2xl font-semibold mb-4">Refund Policy</h3>
                            <p>
                                Weaves Fabrics takes great pride in our customer service and delivering products that meet
                                high-quality standards.
                            </p>
                            <p>
                                If you receive a damaged or defective product, we will provide a replacement or full refund
                                within 2 days of receiving the product.
                            </p>
                            <p className="mt-4">
                                To return an item, email us at{' '}
                                <a href="mailto:shop@kashmirloom.com" className="text-blue-600 hover:underline">
                                    weavesfabrics12@gmail.com
                                </a>{' '}
                                with your order number and details of the transaction.
                            </p>
                        </motion.section> */}

                        {/* Disclaimer */}
                        {/* <motion.section
                            className="text-sm text-gray-600 mt-8 text-center"
                            initial='hidden'
                            whileInView='show'
                            variants={fadeInUp}
                            transition={transition}
                        >
                            <p>All policies are subject to change without prior notice. In case of any conflict, Terms of Service Policy would prevail.</p> */}
                        {/* <div className="flex justify-center space-x-4 mt-4">
                                <a href="/privacy-policy" className="hover:underline">View Privacy Policy</a>
                                <a href="/terms-of-service" className="hover:underline">View Terms of Service</a>
                            </div> */}
                        {/* </motion.section> */}
                        {/* Mission Section */}
                        {/* <div className="grid md:grid-cols-2 gap-8 mb-16">
                            <div className="rounded-lg overflow-hidden">
                                <img
                                    src="/api/placeholder/600/400"
                                    alt="Team working on laptops"
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="bg-gray-50 p-8 rounded-lg">
                                <div className="space-y-4">
                                    <h3 className="text-lg font-semibold text-gray-900">OUR MISSION</h3>
                                    <h2 className="text-2xl font-bold">Empowering Financial Independence</h2>
                                    <p className="text-gray-600">
                                        At Financial Freedom Advisors, our mission is clear: to empower
                                        individuals and families to achieve financial independence. We are
                                        dedicated to providing personalized guidance and comprehensive
                                        solutions that align with our clients' unique goals and aspirations. Our
                                        focus is on helping you build a solid foundation for your financial
                                        future, enabling you to pursue your dreams with confidence.
                                    </p>
                                    <button className="text-blue-600 font-medium hover:text-blue-700">
                                        READ MORE
                                    </button>
                                </div>
                            </div>
                        </div> */}
                        {storyContent.map((story, index) => (
                            <div
                                className={`flex flex-col md:flex-row ${index % 2 === 1 ? "md:flex-row-reverse" : ""
                                    } items-center gap-8 mb-16`}
                                key={index}
                            >
                                {/* Image */}
                                <div className="flex-shrink-0 w-full md:w-1/2 rounded-lg overflow-hidden">
                                    <img
                                        src={story?.image}
                                        alt="Story visualization"
                                        className="w-full h-full object-cover"
                                    />
                                </div>

                                {/* Content */}
                                <div className="bg-gray-50 p-8 rounded-lg w-full md:w-1/2">
                                    <div className="space-y-4">
                                        <h2 className="text-2xl font-bold">{story?.header}</h2>
                                        <h3 className="text-lg">{story?.content}</h3>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <div className="flex justify-center flex-col md:flex-row gap-4" onClick={() => { nav('/tos') }}>
                            <div className='p-3 bg-gray-50 cursor-pointer rounded-sm text-blue-600'>Terms & Condition</div>
                            <div className='p-3 bg-gray-50 cursor-pointer rounded-sm text-blue-600'>Refund & Return</div>
                            <div className='p-3 bg-gray-50 cursor-pointer rounded-sm text-blue-600'>Shipping Policy</div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>
        </>
    )
}

