import React, { createContext, useState, useEffect, useContext } from "react";
import axiosInstance from "../axiosInstance";

// Create a Product Context
const ProductContext = createContext();

// Product Provider Component
export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch Products from API
  const fetchProducts = async () => {
    setLoading(true);
    setError(null); // Clear any previous errors
    try {
      const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_BASE_URL}${"/product"}`,
        { withCredentials: true }
      );
      setProducts(res.data);
    } catch (err) {
      setError(err.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  // Optionally call `fetchProducts` when the component mounts
  useEffect(() => {
    fetchProducts();
  }, []); // Empty dependency array ensures it runs only once

  // Provide state and fetch function
  return (
    <ProductContext.Provider value={{ products, loading, error }}>
      {children}
    </ProductContext.Provider>
  );
};

// Custom Hook to Use Product Context
export const useProductContext = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error("useProductContext must be used within a ProductProvider");
  }
  return context;
};
