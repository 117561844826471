import React from 'react'
import { useState, useEffect } from 'react';


//importing css
import './offer.css'

// //import css for react slick
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

//import navigate
import { useLocation, useNavigate } from 'react-router-dom';

import useFetch from '../../hooks/useFetch';


//importing motion for animation
import { motion, useAnimation } from "framer-motion"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useCurrency } from '../../context/Currency';


export default function Offer() {
  const location = useLocation()
  const nav = useNavigate()
  const { state } = location;
  const { symbol } = useCurrency()
  //for transition
  const controls = useAnimation()
  const transition = { duration: 3, type: "spring", }
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('up');

  const variants = {
    hidden: { bottom: "-2rem" },
    visible: { bottom: "0rem" }
  }

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollTop > lastScrollTop) {
      setScrollDirection('down');
      controls.start('visible');
    } else {
      setScrollDirection('up');
      controls.start('hidden');
    }
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop, controls]);



  const navigate = useNavigate()
  const { data, loading } = useFetch('/category')
  const handleSearch = (keyword) => {
    navigate('/store', { state: keyword })
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data])

  const category = data?.filter((x) => x?.keyword === state?.category)

  return (
    <div className='mt-10'>
      <Swiper
        className='mySwiper'
        modules={[Pagination, Scrollbar, A11y, Autoplay]}
        slidesPerView={1}
        slidesPerGroup={1}
        spaceBetween={0}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
      >
        {category.map((x, i) => (
          <SwiperSlide key={i}>
            <div className="headerImageContainer">
              <img className="headerImg" src={x.catImg} alt={`slide ${i + 1}`} />
              <div className='tint'>
                <div className='text-white font-bold text-xs md:text-6xl'>{x?.catname}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className='category'>
        <div className='categoryTitle'>
          <span className='text-3xl font-bold mb-4 font-sans'>Handwoven {state?.category === "Stoles" ? "Scarves, Stoles, and Shawls" : "Fabrics"}</span>
          {/* <hr className='catline'></hr> */}
        </div>

        <div className='categorySec'>
          {
            (loading) ? (<span>Loading please wait......</span>) : (
              // data.map((cat, i) => (
              category.map((cat, i) => (
                <div key={i} className='categoryItem'
                  // onClick={() => handleSearch(cat.keyword)}
                  onClick={() => nav(`/store?categoryId=${cat?._id}&category=${state?.category}`)}
                >
                  <motion.img
                    initial="hidden"
                    animate={controls}
                    variants={variants}
                    transition={transition}
                    src={cat.catImg} alt=''></motion.img>
                  <div
                    className='catContent bg-[#ece7df] text-center hover:bg-[#d6cec4]' >
                    <h2 className='catTitle text-black'>{cat.catname}</h2>
                  </div>
                </div>
              ))
            )
          }
        </div>
      </div>
    </div>
  )
}
