import React, { useContext, useEffect, useState } from 'react'
//importing css file
import './checkout.css'
//importing icons
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CloseIcon from '@mui/icons-material/Close';

//importing smallProduct
import SmallProducts from '../../components/smallproducts/SmallProducts';
import { TransContext } from '../../context/TransContext';


//importing checkout windows
import MainContactWindow from '../../components/checkoutwindow/MainContactWindow';
import OtpWindow from '../../components/checkoutwindow/OtpWindow';
import AddressWindow from '../../components/checkoutwindow/AddressWindow';
import PayWindow from '../../components/checkoutwindow/PayWindow';
import { AuthContext } from '../../context/AuthContext';
import { useCurrency } from '../../context/Currency';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from '../../axiosInstance';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function CheckOut({ setModal, cartItems }) {
  const { user } = useContext(AuthContext)
  const { symbol } = useCurrency()
  //for mobile no
  const [mobileNo, setMobileNo] = useState("")


  //for top buttons
  const [mainContactWindow, setMainContactWindow] = useState((user.verified === false) ? (true) : (false)) //true
  const [contactWindow, setContactWindow] = useState((user.verified === false) ? (true) : (false)) //true
  // const [mainContactWindow, setMainContactWindow] = useState(false) //true
  // const [contactWindow, setContactWindow] = useState(false) //true
  const [otpWindow, setOtpWindow] = useState(false)

  const [addressCompleted, setAddressCompleted] = useState(false)
  const [addressWindow, setAddressWindow] = useState((user.verified === false) ? (false) : (true))

  const [paymentCompleted, setPaymentCopleted] = useState(false)
  const [payWinow, setPayWindow] = useState(false)

  const { priceDetails } = useContext(TransContext)

  //for firebase mobile otp
  const topaddressclick = () => {
    setAddressCompleted(false)
    setAddressWindow(true)
    setPayWindow(false)
  }
  const [clientSecret, setClientSecret] = useState(null);
  const [estimatedPrice, setEstimatedPrice] = useState(0)
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const getEstimatedPrice = async () => {
      try {
        const res = await axiosInstance.post('payment/delivery-estimate');
        console.log(res, "data")
        setEstimatedPrice(res.data.data.output.rateReplyDetails[0].ratedShipmentDetails[0].totalNetCharge);
        console.log(res.data.data.output.rateReplyDetails[0].ratedShipmentDetails[0].totalNetCharge, "amount")
      } catch (error) {
        setMessage("Error fetching client secret");
      }
    };
    getEstimatedPrice();
  }, []);

  // Fetch client secret from server
  useEffect(() => {
    const getClientSecret = async () => {
      try {
        const res = await axiosInstance.post('payment/paynow', { amount: (priceDetails.totalPrice + estimatedPrice) * 100 });
        setClientSecret(res.data.clientSecret);
      } catch (error) {
        setMessage("Error fetching client secret");
      }
    };
    if (estimatedPrice > 0) {
      getClientSecret();
    }
  }, [estimatedPrice]);

  // Stripe Elements options
  const options = {
    clientSecret,
    appearance: { theme: "stripe" }, // Wrapped in appearance
  };

  return (
    <div className='checkout'>
      <div className={(addressWindow) ? ("checkOutContainer addresswide") : ("checkOutContainer")}>
        <CloseIcon onClick={() => setModal(false)} className='checkoutcloseIcon'></CloseIcon>
        <div className={addressWindow ? "left addresswide p-4" : "left p-4"}>
          <div className='top'>
            <button disabled={(user.verified === false)}>Contact</button>
            <button disabled={!addressCompleted} onClick={topaddressclick}>Address</button>
            <button disabled={!paymentCompleted}>Pay</button>
          </div>
          {/* for mobile no input taking */}
          {
            contactWindow && (
              <MainContactWindow mobileNo={mobileNo} setMobileNo={setMobileNo} setContactWindow={setContactWindow} setAddressWindow={setAddressWindow}></MainContactWindow>
            )
          }
          {/* for verify otp  */}
          {
            otpWindow && (
              <OtpWindow mobileNo={mobileNo} setMainContactWindow={setMainContactWindow} setOtpWindow={setOtpWindow} setAddressWindow={setAddressWindow}></OtpWindow>
            )
          }
          {/* for getting address */}
          {
            addressWindow && (
              <AddressWindow setAddressCompleted={setAddressCompleted} setAddressWindow={setAddressWindow} setPayWindow={setPayWindow}></AddressWindow>
            )
          }
          {/* for payment */}
          {
            payWinow && (
              <Elements stripe={stripePromise} options={options}>
                <PayWindow></PayWindow>
              </Elements>
            )
          }
        </div>
        <div className={addressWindow ? "right addresswide" : "right"}>
          <h1 className='orderSummeryHead'><LocalMallIcon></LocalMallIcon> Order Summery</h1>
          <div className='orderSummery'>
            {
              cartItems.map((item, i) => (
                <SmallProducts key={i} productId={item.productId} productSize={item.size} productQnt={item.qnt}></SmallProducts>
              ))
            }
          </div>
          <div className='orderPriceDetails'>
            {/* <span className='gstInfo'>GST Charge Included</span> */}
            <div className='totalBox'>
              <span>Subtotal:</span>
              <span>{symbol}{priceDetails.totalPrice.toFixed(2)}</span>
            </div>
            <div className='totalBox'>
              <span>Delivery Charges:</span>
              <span>+ {symbol}{estimatedPrice.toFixed(2)}</span>
            </div>
            {/* <div className='totalBox'>
              <span>Shipping charge:</span>
              <span>To be calculated</span>
            </div> */}
            <hr></hr>
            <div className='grandTotalBox'>
              <span>To Pay</span>
              <span>{symbol}{(priceDetails.totalPrice + estimatedPrice).toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
