import React, { useState } from 'react';
import './ZipCode.model.css'; // Add appropriate styles

const ZipcodeModal = ({ onSubmit, onClose }) => {
    const [addressObj, setAddressObj] = useState({ zipcode: "", country: "US" })
    const [error, setError] = useState(null)

    // Regex patterns for zip codes by country
    const zipCodePatterns = {
        US: /^[0-9]{5}(-[0-9]{4})?$/,            // United States (5 digits, optionally 4 more)
        UK: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/, // United Kingdom
        AU: /^[0-9]{4}$/,                        // Australia (4 digits)
        NZ: /^[0-9]{4}$/,                        // New Zealand (4 digits)
        DE: /^[0-9]{5}$/,                        // Germany (5 digits)
        EU: /^[0-9A-Za-z -]+$/                    // Europe (varies by country, so this allows a mix)
    };

    const validateZipCode = () => {
        const { zipCode, country } = addressObj
        const pattern = zipCodePatterns[country]
        if (pattern && !pattern.test(zipCode)) {
            setError("Please enter a valid zip code for the selected country.")
            return false
        }
        return true
    }

    const handleSubmit = (e) => {
        if (!validateZipCode()) return
        setError(null)
        e.preventDefault();
        onSubmit(addressObj);
    };

    const handleChange = (e) => {
        const { id, value } = e.target
        if (id === 'country') {
            setAddressObj((prev) => ({ ...prev, country: value, zipCode: '' }))
            setError(null)
        } else {
            setAddressObj((prev) => ({ ...prev, [id]: value }))
        }
    }

    return (
        <div className='modalBackdrop'>
            <div className='modalContent'>
                <h3>Enter Your Country Code and Zipcode</h3>
                <form className='addressForm' onSubmit={handleSubmit}>
                    <div className='inputBoxContainer'>
                        <div className='inputBox'>
                            <div className='inputBox'>
                                <span>Country *</span>
                                <select
                                    name="country"
                                    id="country"
                                    onChange={handleChange}
                                    value={addressObj.country}
                                >
                                    <option value="US">United States</option>
                                    <option value="EU">Europe</option>
                                    <option value="UK">United Kingdom</option>
                                    <option value="AU">Australia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="DE">Germany</option>
                                </select>
                            </div>
                            <span>Zip Code *</span>
                            <input
                                id='zipCode'
                                type='number'
                                defaultValue={addressObj.zipCode}
                                onChange={handleChange}
                                placeholder={zipCodePatterns[addressObj.country || "US"] ? "e.g., 32003" : "Enter valid postal code"}
                                required
                            />
                        </div>
                    </div>
                </form>
                <div className="flex flex-col">
                    <span className='errMsg'>{error}</span>
                    <div className="flex flex-row justify-between">
                        <button
                            className='underline'
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className='underline'
                            onClick={handleSubmit}
                        >
                            Continue
                        </button>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default ZipcodeModal;
