import React from 'react';
import './header.css';
// import HeaderImage from '../../assets/threadHeroSection.jpg';
import Slider1 from '../../assets/slider-1.jpeg';
import Slider2 from '../../assets/slider-2.jpeg';
import Slider3 from '../../assets/slider-3.jpeg';
import Slider4 from '../../assets/slider-4.jpeg';
import Slider5 from '../../assets/slider-5.jpeg';
import Slider6 from '../../assets/slider-6.jpeg';
import Slider7 from '../../assets/slider-7.jpeg';
import Story1 from '../../assets/story1.jpeg';
import Story2 from '../../assets/story2.jpeg';
import Story3 from '../../assets/story3.jpeg';
import Story4 from '../../assets/story4.jpeg';
import Story5 from '../../assets/story5.jpeg';
import Story6 from '../../assets/story6.jpeg';
import Stole from '../../assets/homeStole.jpeg';
import logo from '../../assets/weavesFabricsLogo.png';
import Navbar from '../navbar/Navbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const nav = useNavigate()
  const images = [Slider1, Slider2, Slider3, Slider4, Slider5, Slider6, Slider7];
  const storyContent = [
    { image: Story1, content: "Crafting Tradition, Embracing Innovation", header: "Our Story" },
    { image: Story2, content: "Where Heritage Meets Modern Threads", header: "Heritage Journey" },
    { image: Story3, content: "Rooted in Tradition, Woven for Today", header: "Legacy of Craft" },
    { image: Story4, content: "Honoring the Past, Weaving the Future", header: "Timeless Tradition" },
    { image: Story5, content: "Heritage in Every Thread, Progress in Every Stitch", header: "Threaded Tales" },
    { image: Story6, content: "Threads of Heritage, Fabric of Innovation", header: "Woven Heritage" }
  ];

  const collections = [
    {
      title: "Scarves, Stoles, and Shawls",
      description: `Explore our stunning collection of unisex scarves, stoles, and shawls featuring Ikat patterns and block prints in diverse designs and colors. A perfect way to add elegance to any look.`,
      image: Stole,
      nav: { title: "category", state: { category: "Stoles" } }
    },
    {
      title: "Handwoven Fabrics",
      description: "Experience the timeless beauty of handwoven fabrics—a true celebration of artistry, craftsmanship, and tradition. Each piece is meticulously crafted by skilled artisans, offering unique textures, patterns, and a distinct touch of individuality.",
      image: "https://img.freepik.com/free-photo/close-up-silky-…fabric-material_23-2147921828.jpg?semt=ais_hybrid",
      nav: { title: "category", state: { category: "Fabrics" } }
    },
    {
      title: "Featured Collection",
      description: "Discover our curated selection of exquisite fabrics, scarves, stoles, and shawls, each piece crafted with exceptional artistry and timeless beauty.",
      image: "https://img.freepik.com/free-photo/top-view-fabric-texture_23-2148952744.jpg",
      nav: { title: "store" }
    },
  ];

  return (
    <>
      <Navbar />
      <div className='topheader'>
        <Swiper
          className='mySwiper'
          modules={[Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {images.map((x, i) => (
            <SwiperSlide key={i}>
              <div className="headerImageContainer">
                <img className="headerImg" src={x} alt={`slide ${i + 1}`} />
                <div className='tint '>
                  <div className='flex justify-center items-center flex-col'>
                    <img className="middle-img" src={logo} alt="" />
                    <div className="text-white text-center font-sans text-sm lg:text-2xl tracking-widest leading-loose">
                      <br />
                      <br />
                      See, Feel, and Buy
                      <br />
                      <span className='text-xl lg:text-3xl font-sans'>
                        Our products:
                      </span>
                      <br />

                      Amazing Style, Grace, and Feeling
                      <br />

                      Encouraging Artisans and Artisan Techniques
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="content-section">
        <p className="section-description font-sans  font-bold text-sm">
          <h4 className='font-extrabold text-lg font-sans'>Welcome to Weaves Fabrics</h4>
          {/* At Weaves Fabrics, we celebrate the art of hand weaving, where every thread tells a story. Our exquisite collection features a diverse range of materials, including silk, cotton, wool, and more, crafted with care by skilled artisans. Each product is thoughtfully designed to meet your sewing, crafting, and fashion needs, ensuring that you find the perfect piece for any occasion. <br /><br /> */}
          The beauty of handcrafted textiles and elevate your creations with our unique fabrics, scarves, stoles, and shawls—each made with passion and dedication. At Weaves Fabrics, experience the charm of artisanal craftsmanship, where enduring tradition meets timeless elegance.
        </p>
      </div>
      <div className="container mx-auto sm:px-10 md:px-24">
        {collections.map((collection, index) => (
          <div
            key={index}
            className={`flex flex-col md:flex-row ${index % 2 === 0 ? "md:flex-row-reverse" : "md:flex-row"
              } items-center my-8 gap-x-16 sm:gap-x-8`} // Added gap-x-8 for extra spacing
          >
            {/* Image */}
            <div className="w-full md:w-1/2 p-4 sm:p-2">
              <img
                src={collection.image}
                alt={collection.title}
                className="w-full max-h-72 md:max-h-96 h-auto object-cover rounded-lg" // Reduced image height
                onClick={() => nav(`/${collection.nav?.title || ""}`, { state: collection.nav?.state })}
              />
            </div>
            <div className="w-full md:w-1/2 p-6"> {/* Increased padding to p-6 */}
              <h2 className="text-2xl font-bold mb-4 font-sans">{collection.title}</h2>
              <p className="text-gray-600 mb-6 font-sans">{collection.description}</p>
              <button
                className="px-4 py-2 bg-black text-white rounded-lg"
                onClick={() => nav(`/${collection.nav?.title || ""}`, { state: collection.nav?.state })}
              >
                Shop Now
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className='topheader'>
        <Swiper
          className='mySwiper'
          modules={[Pagination, Scrollbar, A11y, Autoplay]}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {storyContent.map((x, i) => (
            <SwiperSlide key={i}>
              <div className="headerImageContainer cursor-pointer" onClick={() => nav('/about')}>
                <img className="headerImg" src={x.image} alt={`slide ${i + 1}`} />
                <div className='tint flex flex-col'>
                  <h3 className='text-white font-thin text-3xl md:text-6xl'>{x.header}</h3>
                  <div className='text-white tracking-[4px] mt-3 text-center text-sm md:text-lg'>{x.content}</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}
